<template>
    <a-drawer
        :width="drawerWidth"
        class="task_show_drawer"
        :class="{'task_mobile_drawer': isMobile}"
        :visible="visible"
        :closable="false"
        :zIndex="taskDrawerZIndex"
        :afterVisibleChange="afterVisibleChange"
        @close="closeDrawer()">
        <div class="flex items-center justify-between head_wrapper">
            <div
                v-if="task"
                class="text-base font-semibold truncate label">
                {{task.name}} - {{task.counter}}
            </div>
            <a-skeleton
                v-else
                active
                :paragraph="{ rows: 1 }" />
            <div class="flex items-center">
                <a-button
                    v-if="task && dropActions && dropActions.edit && task.editable"
                    type="ui"
                    ghost
                    flaticon
                    shape="circle"
                    icon="fi-rr-edit"
                    class="ml-2"
                    @click="edit()" />
                <a-button
                    type="ui"
                    class="ml-2"
                    ghost
                    shape="circle"
                    flaticon
                    icon="fi-rr-cross"
                    @click="closeDrawer()" />
            </div>
        </div>
        <div class="tab_wrapper">
            <a-tabs 
                v-if="task"
                v-model="tab"
                @change="changeTab">
                <a-tab-pane
                    v-for="tab in task.tabs"
                    :key="tab.code">
                    <template #tab>
                        <div class="flex">
                            <span>{{ tab.name }}</span>
                            <a-badge 
                                v-if="tab.code === 'comments' && tab.counter" 
                                class="ml-1" 
                                :count="commentsCount" />
                            <a-badge    
                                v-if="tab.code === 'files' && fileCount" 
                                class="ml-1" 
                                :count="fileCount"
                                :overflow-count="99" />
                        </div>
                    </template>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div 
            class="body_wrapper task_body_wrap" 
            ref="bodyWrap"
            :class="[!disabledWrapper ? 'body_wrapper__padding' : 'body_wrapper__full']">
            <component 
                v-if="task"
                :is="taskStep"
                :task="task"
                :isAuthor="isAuthor" />

            <a-row :gutter="50">
                <!-- <a-col
                    v-if="columnMobile && showAside && isMobile"
                    class="mb-6">
                    <TaskAsideHeader 
                        :task="task" 
                        :closeDrawer="closeDrawer" />
                </a-col> -->
                <a-col 
                    :sm="24" 
                    :lg="!showAside || isMobile ? 24 : 16">
                    <template v-if="task">
                        <a-tabs
                            :activeKey="tab" 
                            class="body_tab"
                            :class="isMobile && 'mobile'">
                            <a-tab-pane
                                v-for="tab in task.tabs"
                                :key="tab.code" 
                                :tab="tab.name">
                                <TabSwitch
                                    :tab="tab"
                                    :task="task" 
                                    :myTask="myTask"
                                    :code="tab.code"
                                    :dropActions="dropActions"
                                    :reloadTask="reloadTask"
                                    :getCommentsCount="getCommentsCount"
                                    :edit="edit"
                                    :bodyWrap="taskContainer"
                                    :openTask="openTask"
                                    :isOperator="isOperator"
                                    :isAuthor="isAuthor"
                                    :isMobile="isMobile"
                                    :closeDrawer="closeDrawer" />
                            </a-tab-pane>
                        </a-tabs>
                    </template>
                    <template v-else>
                        <a-skeleton
                            active
                            :paragraph="{ rows: 6 }" />
                    </template>
                </a-col>
                <a-col
                    v-if="columnMobile && showAside && !isMobile"
                    class="mt-6 mb:mt-0 lg:mt-0"
                    :sm="24"
                    :lg="8">
                    <TaskAside 
                        :task="task" 
                        :closeDrawer="closeDrawer"
                        :isMobile="isMobile" />
                </a-col>
            </a-row>
        </div>
        <div class="flex items-center footer_wrapper">
            <template v-if="task">
                <TaskActions 
                    :item="task"
                    :editFull="edit"
                    :getPopupContainer="getPopupContainer"
                    :copyFunc="copy"
                    :closeDrawer="closeDrawer"
                    :deleteFunc="deleteTask"
                    :addSubtaskFunc="addSubtask"
                    :addTaskFunc="addTask"
                    :dropTrigger="['hover']" />
            </template>
            <a-skeleton 
                v-else 
                active 
                :paragraph="{ rows: 1 }" />
        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import TaskActions from '../TaskActions/Drawer.vue'
import TaskAside from './TaskAside.vue'
import TaskAsideHeader from './TaskAsideHeader.vue'
import TabSwitch from './TabWidgets/TabSwitch.vue'
import StatusList from './StatusList.vue'
import { mapState, mapGetters } from 'vuex'
import { socketEmitJoin, socketEmitLeave } from '@/utils/socketUtils.js'
export default {
    name: 'TaskShowDrawer',
    components: {
        TaskAside,
        TaskAsideHeader,
        TabSwitch,
        TaskActions,
        StatusList,
    },
    data() {
        return {
            loading: true,
            commentsCount: 0,
            btn1Loading: false,
            btn2Loading: false,
            editStatus: false,
            tab: '',
            conference: {},
            fileCount: 0,
            closeQuery: null
        }
    },
    computed: {
        ...mapState({
            task: state => state.task.task,
            user: state => state.user.user,
            windowWidth: state => state.windowWidth,
            taskTypeActiveTab: state => state.task.taskTypeActiveTab,
            taskDrawerZIndex: state => state.task.taskDrawerZIndex
        }),
        ...mapGetters({
            taskActions: 'task/taskActions'
        }),
        taskStep() {
            if(this.task.show_step && !this.isMobile)
                return () => import('./StatusList.vue')
            else
                return null
        },
        dropActions() {
            const actions = this.taskActions(this.task.task_type, this.task.id)
            if(actions)
                return actions.actions
            else
                return null
        },
        activeTab() {
            if(this.task?.tabs?.length) {
                const find = this.task.tabs.find(f => f.code === this.tab)
                if(find)
                    return find
                else
                    return null
            } else
                return null
        },
        showAside() {
            if(this.activeTab?.showAside)
                return true
            else
                return false
        },
        disabledWrapper() {
            if(this.activeTab?.disabledWrapper)
                return true
            else {
                return false
            }  
        },
        columnMobile() {
            if(this.windowWidth > 764 && !this.isMobile)
                return true
            else {
                if(this.tab === 'task')
                    return true
                else
                    return false
            }
        },
        visible: {
            get() {
                return this.$store.state.task.taskShow
            },
            set(val) {
                this.$store.commit('task/CHANGE_TASK_SHOW', val)
            }
        },
        drawerWidth() {
            if(this.windowWidth > 1300)
                return 1300
            else if(this.windowWidth < 1300 && this.windowWidth > 500)
                return this.windowWidth - 30
            else
                return this.windowWidth
        },
        isOperator() {
            if(this.user.id === this.task.operator.id 
            )
                return true
            else
                return false
        },
        myTaskProcessed() {
            if(this.user && this.user.id === this.task.owner.id || this.user.id === this.task.operator.id 
            )
                return true
            else
                return false
        },
        isAuthor() {
            if(this.user?.id === this.task.owner.id)
                return true
            else
                return false
        },
        myTask() {
            if(this.user && this.user.id === this.task.owner.id || this.user.id === this.task.operator.id || this.task.workgroup?.author === this.user?.id || this.task.project?.author === this.user?.id)
                return true
            else
                return false
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    created: async function() {
        if(this.$route.query.task)
            this.openTaskDrawer()
    },
    watch: {
        '$route.name'() {
            this.visible = false
        },
        '$route.query'(val) {
            if(val.task) {
                this.openTaskDrawer()
            }
        },
        visible(val) {
            if(val) {
                if(this.editStatus)
                    this.editStatus = false
                this.getTask()
            } else {
                if(!this.editStatus)
                    this.close()
            }
        }
    },
    sockets: {
        task_update({data}) {
            if(data) {
                this.$store.commit('task/UPDATE_SHOW_TASK_SOCKET', data)
            }
        }
    },
    methods: {
        taskContainer() {
            return this.$refs.bodyWrap
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.$store.commit('task/SET_TASK_DRAWER_OPTIONS', null)
                this.fileCount = null
                // this.$store.commit('task/SET_PAGE_NAME', {
                //     pageName: null
                // })
            } else {
                this.$message.destroy()
            }
        },
        closeDrawer(query = null) {
            this.closeQuery = query
            this.visible = false
            this.$store.commit('task/SET_TASK_POINT_LIST', [])
        },
        getPopupContainer() {
            return document.querySelector('.task_body_wrap')
        },
        changeTab(val) {
            let query = JSON.parse(JSON.stringify(this.$route.query))
            query.stab = val
            this.$router.push({query})
        },
        openSprint(id){
            if(!this.$route.query.sprint){
                let query = Object.assign({}, this.$route.query, )
                this.$store.commit('task/SET_SPRINT_DRAWER_ZINDEX', 1100)
                query.sprint = id
                this.$router.replace({query: {}})
                this.$router.push({query})
            }
        },
        async deleteTask() {
            try {
                this.btn1Loading = true
                const res = await this.$store.dispatch('task/deleteTask', this.task)
                if(res) {
                    this.$message.success(this.$t('task.task_deleted'))
                    this.visible = false
                    const pageName = this.$store.state.task.pageName
                    eventBus.$emit(`table_row_${pageName}`, {
                        action: 'delete',
                        row: this.task
                    })

                }
            } catch(e) {
                this.$message.error(this.$t('task.error'))
            } finally {
                this.btn1Loading = false
            }
        },
        async openTask() {
            let query = Object.assign({}, this.$route.query)
            delete query.task
            await this.$router.push({query})
            this.reloadTask(this.task.parent)
        },
        async openTaskById(id) {
            let query = Object.assign({}, this.$route.query)
            delete query.task
            await this.$router.push({query})
            this.reloadTask({ id: id })
        },
        async reloadTask(item) {
            socketEmitLeave(`detail_${this.task.id}`)
            this.$store.commit('task/CLEAR_TASK_ACTIONS', {
                task_type: this.task.task_type,
                id: this.task.id
            })
            this.$store.commit('task/SET_TASK', null)
            let query = Object.assign({}, this.$route.query)
            query.task = item.id
            await this.$router.push({query})
            this.getTask()
        },
        addSubtask() {
            this.visible = false
            socketEmitLeave(`detail_${this.task.id}`)
            this.$store.commit('task/SET_TASK_TYPE', 'task')
            eventBus.$emit('ADD_WATCH', {type: 'subtask', data: this.task})
        },
        addTask() {
            this.visible = false
            socketEmitLeave(`detail_${this.task.id}`)
            this.$store.commit('task/SET_TASK_TYPE', 'task')
            eventBus.$emit('ADD_WATCH', {type: 'subtask', data: this.task})
        },
        copy() {
            this.visible = false
            socketEmitLeave(`detail_${this.task.id}`)
            this.$store.commit('task/SET_TASK_TYPE', this.task.task_type)
            eventBus.$emit('ADD_WATCH', {type: 'copy', data: this.task})
        },
        edit() {
            this.visible = false
            this.editStatus = true
            socketEmitLeave(`detail_${this.task.id}`)
            eventBus.$emit('EDIT_TASK', {
                back: true,
                task_type: this.task.task_type || 'task'
            })
        },
        close() {
            let query = Object.assign({}, this.$route.query)
            if(query.task) {
                if(query.stab)
                    delete query.stab
                delete query.task
                if(this.closeQuery) {
                    query = {...this.closeQuery}
                    this.closeQuery = null
                }
                /*if(query.sprint)
                    delete query.sprint*/
                this.$router.push({query})
            }

            if(this.task?.tabs?.length)
                this.tab = this.task.tabs[0].code
            else
                this.tab = 'task'

            if(this.task) {
                socketEmitLeave(`detail_${this.task.id}`)
                this.$store.commit('task/CLEAR_TASK_ACTIONS', {
                    task_type: this.task.task_type,
                    id: this.task.id
                })
            }
            this.$store.commit('task/SET_TASK', null)
        },
        async getTask() {
            let {task} = Object.assign({}, this.$route.query)

            if(!task) {
                task = this.task.id
            }

            try {
                socketEmitJoin(`detail_${task}`)
                await this.$store.dispatch('task/getFullTask', task)
                await this.getCommentsCount()
                await this.getFileCount(task)

                const query = JSON.parse(JSON.stringify(this.$route.query))
                if(query.stab)
                    this.tab = query.stab
                else {
                    if(this.task?.tabs?.length) {
                        this.tab = this.task.tabs[0].code
                    } else {
                        this.tab = 'task'
                    }
                }
            } catch(error) {
                if(error && error.detail) {
                    if(error.detail === 'Не найдено.' || error.detail === 'Страница не найдена.' || error.detail === 'У вас недостаточно прав для выполнения данного действия.') {
                        this.$message.warning(this.$t('task.task_not_found'))
                        if(this.$route.query?.task)
                            this.$store.commit('task/DELETE_TASK', { id: this.$route.query.task })
                    } else {
                        this.$message.error(this.$t('task.error'))
                    }
                } else {
                    this.$message.error(this.$t('task.error'))
                }
                this.close()
                this.visible = false
            }
        },
        async getFileCount(taskId) {
            const attachmentsCount = await this.$http(
                `attachments/${ taskId }/aggregate/`)
            this.fileCount = attachmentsCount.data.files
        },
        async getCommentsCount(){
            let {task} = Object.assign({}, this.$route.query)
            let {data} = await this.$http('comments/count/', {params: {related_object: task}})
            this.commentsCount = Number(data) 
        },
        openTaskDrawer() {
            const query = {...this.$route.query}
            if(query?.viewGroup || 
                query?.viewProject ||
                query?.organization
            ) {
                if(query.sprint || this.$store.state.task.sprintAddTaskShow)
                    this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 999999)
                else
                    this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 1010)
            }
            
            this.visible = true
        }
    },
    mounted() {
        eventBus.$on('CLOSE_SHOW_TASK_DRAWER', () => {
            this.closeDrawer()
        })
        eventBus.$on('UPDATE_TASK_DRAWER', () => {
            if(this.visible) {
                this.$store.commit('task/SET_TASK', null)
                this.getTask()
            }
        }),
        eventBus.$on('OPEN_TASK_DRAWER', taskId => {
            this.openTaskById(taskId)
        })

    },
    beforeDestroy() {
        eventBus.$off('CLOSE_SHOW_TASK_DRAWER')
        eventBus.$off('UPDATE_TASK_DRAWER')
        eventBus.$off('OPEN_TASK_DRAWER')
        
    }
}
</script>

<style lang="scss">
.task_show_drawer{
    .task_sidebar{
        padding: 15px;
        background: #fafafa;
    }
    .ant-drawer-body,
    .ant-drawer-content{
        overflow: hidden;
    }
    .ant-drawer-body{
        padding: 0px;
        height: 100%;
    }
    .tab_wrapper{
        // will-change: transform;
        height: 40px;
        @media (min-width: 765px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        border-bottom: 1px solid var(--borderColor);
        .ant-tabs-bar{
            border: 0px;
        }
        .ant-tabs-tab{
            padding: 10px 16px;
        }
    }
    .head_wrapper{
    
        height: 40px;
        border-bottom: 1px solid var(--borderColor);
        padding: 10px 30px;
        background: var(--bgColor);
        .ant-skeleton-paragraph{
            display: none;
        }
        .ant-skeleton-content{
            .ant-skeleton-title{
                width: 90%!important;
                margin: 0px;
                height: 20px;
            }
        }
    }
    .body_wrapper{
        height: calc(100% - 120px);
        overflow-y: scroll;
        &__padding{
            padding: 15px;
            @media (min-width: 1024px) {
                padding: 30px;
            }
        }
        &__full{
            .ant-tabs-tabpane,
            .ant-tabs-content,
            .body_tab,
            .ant-col,
            .ant-row{
                height: 100%;
            }
        }
        .parent_task{
            padding-top: 20px;
            border-top: 1px solid var(--borderColor);
        }
        .body_tab{
            & > .ant-tabs-bar{
                display: none;
            }
        }
        .body_tab.mobile {
            overflow: visible;
            .ant-collapse-content-box {
                padding-top: 5px;
                padding: 10px 15px;
            }
        }
        .sidebar_item{
            &:not(:last-child){
                border-bottom: 1px solid var(--borderColor);
                padding-bottom: 15px;
            }
            &:not(:first-child){
                padding-top: 15px;
            }
            .visor_item{
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
    }
    .footer_wrapper{
        // will-change: transform;

        height: 40px;
        padding: 10px 30px;
        background: var(--bgColor);
        border-top: 1px solid var(--borderColor);
        .ant-skeleton-paragraph{
            display: none;
        }
        .ant-skeleton-content{
            .ant-skeleton-title{
                width: 90%!important;
                margin: 0px;
                height: 20px;
            }
        }
    }

    .body_contractor{
        width: 40%;
    }
}
.task_mobile_drawer {
    .body_wrapper {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        // will-change: transform;
        height: calc(100% - 128px);
    }
    .head_wrapper {
        padding: 10px 15px;
    }
    .footer_wrapper {
        padding: 10px 15px;
        height: 48px;
        .dots_btn{
            width: 55px;
        }
    }
}
</style>
