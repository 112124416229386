<template>
    <a-drawer
        :width="drawerWidth"
        class="task_add_select_drawer"
        :visible="visible"
        :closable="false"
        destroyOnClose
        :zIndex="1050"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_header">
            <div class="drawer_title">Добавить задачу в спринт</div>
            <div class="flex items-center pl-3">
                <a-button 
                    type="ui" 
                    ghost
                    flaticon
                    shape="circle"
                    icon="fi-rr-cross"
                    @click="visible = false" />
            </div>
        </div>
        <div ref="drawerBody" class="drawer_body">
            <div class="mb-2">
                <PageFilter 
                    :model="model"
                    :key="pageName"
                    size="large"
                    :getPopupContainer="getPopupContainer"
                    :page_name="pageName"/>
            </div>
            <a-spin 
                v-if="task"
                :spinning="listLoading" 
                class="w-full">
                <SprintSelectCard 
                    v-for="sprint in sprintList" 
                    :key="sprint.id" 
                    :task="task"
                    :closeDrawer="closeDrawer"
                    :sprint="sprint" />
            </a-spin>
            <div class="flex justify-end pt-1">
                <a-pagination
                    :current="page"
                    :show-size-changer="pageSizeOptions.length > 1"
                    :page-size.sync="pageSize"
                    :defaultPageSize="Number(pageSize)"
                    :pageSizeOptions="pageSizeOptions"
                    :total="count"
                    hideOnSinglePage
                    show-less-items
                    @showSizeChange="sizeSwicth"
                    @change="changePage">
                    <template slot="buildOptionText" slot-scope="props">
                        {{ props.value }}
                    </template>
                </a-pagination>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import SprintSelectCard from './SprintSelectCard.vue'
import PageFilter from '@/components/PageFilter'
export default {
    components: {
        SprintSelectCard,
        PageFilter
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 795)
                return 795
            else {
                return '100%'
            }
        },
    },
    data() {
        return {
            task: null,
            model: 'tasks.TaskSprintModel',
            visible: false,
            sprintList: [],
            count: 0,
            page: 1,
            pageName: 'sprint_list_select',
            pageSize: 15,
            listLoading: false,
            pageSizeOptions: ['15', '30', '50']
        }
    },
    methods: {
        closeDrawer() {
            this.visible = false
        },
        sizeSwicth(current, pageSize) {
            this.page = 1
            this.pageSize = Number(pageSize)
            this.getSprints()
        },
        changePage(page) {
            this.page = page
            this.getSprints()
        },
        getPopupContainer() {
            return this.$refs.drawerBody
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.sprintList = []
                this.count = 0
                this.page = 1
                this.task = null
            } else {
                this.getSprints()
            }
        },
        async getSprints(){
            try{
                this.listLoading = true
                const params = { 
                    page: this.page,
                    page_name: this.pageName,
                    page_size: this.pageSize,
                    for_set_task: this.task.id
                }
                const { data } = await this.$http(`/tasks/sprint/list/`, { params })
                if(data) {
                    this.sprintList = data.results
                    this.count = data.count
                }
            }
            catch(e) {
                console.log(e)
            }
            finally{
                this.listLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on('task_add_sprint', task => {
            this.task = task
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('task_add_sprint')
    }
}
</script>

<style lang="scss" scoped>
.task_add_select_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: initial;
        }
        .ant-drawer-body{
            padding: 0px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .drawer_header{
            padding: 10px 30px;
            border-bottom: 1px solid var(--border2);
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .drawer_body{
            overflow-y: auto;
            flex-grow: 1;
            padding: 20px 30px;
        }
    }
}
</style>