<template>
    <div>
        <swiper 
            ref="swiper"
            class="h-full"
            :options="swiperOption">
            <swiper-slide
                v-for="tab in userTypeTab"
                :key="tab.value">
                <a-button
                    block
                    size="large"
                    class="mr-2.5 last:mr-0 button-gray"
                    :class="activeUserTab === tab.value && 'active'"
                    @click="selectUserTab(tab.value)">
                    {{ tab.label }}
                </a-button>
            </swiper-slide>
        </swiper>
        <!-- <div class="flex">
            <a-button
                v-for="tab in userTypeTab"
                :key="tab.value"
                size="large"
                class="mr-2.5 last:mr-0 button-gray"
                :class="activeUserTab === tab.value && 'active'"
                block
                @click="selectUserTab(tab.value)">
                {{ tab.label }}
            </a-button>
        </div> -->
        <div v-show="activeUserTab === 'operator'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="formInfo.operator"
                    :rules="formInfo.operator.rules"
                    :label="formInfo.operator.title"
                    prop="operator"
                    class="mb-0">
                    <div>
                        <UserDrawer
                            v-model="value.operator"
                            :taskId="value.id ? value.id : null"
                            :id="value.id || defaultUserSelectId"
                            inputSize="large"
                            class="w-full"
                            :disabled="value.is_auction"
                            :filters="
                                formInfo.operator.filters
                                    ? formInfo.operator.filters
                                    : null
                            "
                            :oldSelected="checkOldSelect(formInfo.operator)"
                            :title="
                                formInfo.operator.drawerTitle ||
                                    $t('task.select_performer')
                            "/>
                    </div>
                    <a-checkbox
                        v-if="formInfo.operator.auction"
                        v-model="value.is_auction"
                        @click="selectAuction()">
                        {{ $t("Activate auction") }}
                    </a-checkbox>
                </a-form-model-item>
            </div>
        </div>

        <div v-show="activeUserTab === 'owner'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="!edit && formInfo.owner"
                    :rules="formInfo.owner.rules"
                    :label="formInfo.owner.title"
                    class="mb-0"
                    prop="owner">
                    <UserDrawer
                        :id="value.id || defaultUserSelectId"
                        v-model="value.owner"
                        :taskId="value.id ? value.id : null"
                        inputSize="large"
                        :title="
                            formInfo.owner.drawerTitle ||
                                $t('task.select_author')
                        "/>
                </a-form-model-item>
            </div>
        </div>

        <div v-show="activeUserTab === 'visors'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="formInfo.visors"
                    :rules="formInfo.visors.rules"
                    :label="formInfo.visors.title"
                    class="mb-0"
                    prop="visors">
                    <UserDrawer
                        :id="value.id || defaultUserSelectId"
                        :metadata="{ key: 'visors', value: value.metadata }"
                        :changeMetadata="changeMetadata"
                        v-model="value.visors"
                        :taskId="value.id ? value.id : null"
                        multiple
                        inputSize="large"
                        :title="
                            formInfo.visors.drawerTitle ||
                                $t('task.select_observers')
                        "/>
                </a-form-model-item>
            </div>
        </div>
    </div>
</template>

<script>
import UserDrawer from "@apps/DrawerSelect/index.vue";

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper, 
        SwiperSlide,
        UserDrawer
    },
    props: {
        value: { // form
            type: Object,
            required: true
        },
        formInfo: {
            type: Object,
            required: true
        },
        defaultUserSelectId: {
            type: String,
            default: 'empty_task'
        },
        edit: {
            type: Boolean,
            required: false
        },
        changeMetadata: {
            type: Function,
            required: true
        },
        checkOldSelect: {
            type: Function,
            required: true
        }
    },
    data() {
        return {

            swiperOption: {
                spaceBetween: 15,
                slidesPerView: 1.2,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                }
            },
            activeUserTab: 'operator',
            userTypeTab: [
                {
                    value: "operator",
                    label: this.$t("Operator"),
                },
                // {
                //     value: "cooperator",
                //     label: this.$t("Cooperator"),
                // },
                {
                    value: "owner",
                    label: this.$t("Owner"),
                },
                {
                    value: "visors",
                    label: this.$t("Visors"),
                },
            ],
        }
    },
    methods: {
        selectUserTab(value) {
            this.activeUserTab = value;
        },
        selectAuction() {
            const form = {
                ...this.value,
                operator: null,
                is_auction: !this.value.is_auction
            }
            this.$emit('input', form)
        },
    }
}
</script>