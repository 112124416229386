<template>
    <div>
        <a-form-model-item
            v-if="formInfo.priority"
            :rules="formInfo.priority.rules"
            class="mb-0"
            prop="priority">
            <Priority
                v-model="value.priority"
                :key="edit || visible"/>
        </a-form-model-item>
    </div>
</template>

<script>
import Priority from "../../Priority.vue";


export default {
    components: { 
        Priority
    },
    props: {
        value: { // form
            type: Object,
            required: true
        },
        formInfo: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>
