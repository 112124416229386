<template>
    <a-drawer
        :width="drawerWidth"
        class="task_select_drawer"
        :visible="visible"
        :closable="false"
        destroyOnClose
        :zIndex="1200"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_header">
            <div class="drawer_title">Добавить задачи в спринт</div>
            <div class="flex items-center pl-3">
                <a-button 
                    type="ui" 
                    ghost
                    flaticon
                    shape="circle"
                    icon="fi-rr-cross"
                    @click="visible = false" />
            </div>
        </div>
        <div ref="drawerBody" class="drawer_body">
            <div class="flex items-center justify-between mb-4">
                <PageFilter 
                    model="tasks.TaskModel"
                    :key="page_name"
                    size="large"
                    :excludeFields="['sprint__exclude', 'sprint']"
                    :getPopupContainer="getPopupContainer"
                    :page_name="page_name" />
                <div class="flex items-center">
                    <SettingsButton
                        :pageName="page_name"
                        :zIndex="1250"
                        class="mr-2" />
                    <a-button 
                        type="primary"
                        size="large"
                        :disabled="selected.length ? false : true"
                        flaticon
                        :loading="loading"
                        icon="fi-rr-plus"
                        @click="addToSprint()">
                        Добавить задачи в спринт
                    </a-button>
                </div>
            </div>
            <UniversalTable 
                v-if="visible"
                model="tasks.TaskModel"
                :pageName="page_name"
                tableType="tasks_to_sprint"
                autoHeight
                useSelect
                endpoint="/tasks/sprint/task/list/"
                :params="queryParams"
                :openHandler="openTask"
                :main="false"
                taskType="task"
                showChildren
                extendDrawer
                :hash="false"
                @rowSelected="rowSelected" />
        </div>
    </a-drawer>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import UniversalTable from '@/components/TableWidgets/UniversalTable'
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import eventBus from '@/utils/eventBus'
export default {
    components: {
        PageFilter,
        UniversalTable,
        SettingsButton
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 1375)
                return 1375
            else {
                return '100%'
            }
        },
        page_name() {
            return `tasks_select_sprint_${this.sid}_task.TaskModel`
        },
        isInject() {
            return this.inject ? `_inject` : ''
        },
        visible: {
            get() {
                return this.$store.state.task.sprintAddTaskShow
            },
            set(value) {
                this.$store.commit('task/SET_SPRINT_ADD_TASK_SHOW', value)
            }
        }
    },
    data() {
        return {
            queryParams: {},
            selected: [],
            sid: null,
            loading: false,
            inject: false
        }
    },
    methods: {
        async addToSprint() {
            try {
                this.loading = true
                const { data } = await this.$http.post('/tasks/task/bulk_set_sprint/', {
                    sprint: this.sid,
                    tasks: this.selected
                })
                if(data === 'ok') {
                    eventBus.$emit(`update_sprints_list${this.isInject}`)
                    eventBus.$emit(`update_sprint_${this.sid}`)
                    this.visible = false
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        rowSelected(e) {
            const task = e.data
            const index = this.selected.findIndex(f => f === task.id)
            if(index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(task.id)
            }
        },
        openDrawer() {
            this.visible = true
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.sid = null
                this.selected = []
                this.queryParams = {}
                this.inject = false
            } else {
                if(this.$route.query?.viewProject || this.$route.query?.viewGroup)
                    this.inject = true
            }
        },
        openTask(item) {
            const query = Object.assign({}, this.$route.query)
            if(query.task && Number(query.task) !== item.id || !query.task) {
                this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 999999)
                query.task = item.id
                this.$router.push({query})
            }
        },
        getPopupContainer() {
            return this.$refs.drawerBody
        },
    },
    mounted() {
        eventBus.$on('sprint_add_task', sid => {
            this.sid = sid
            this.queryParams = {
                sprint: sid
            }
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('sprint_add_task')
    }
}
</script>

<style lang="scss" scoped>
.task_select_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-body{
            padding: 0px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .drawer_header{
            padding: 10px 30px;
            border-bottom: 1px solid var(--border2);
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .drawer_body{
            overflow-y: auto;
            flex-grow: 1;
            padding: 20px 30px;
        }
    }
}
</style>