<template>
    <div>
        <a-form-model-item
            v-if="formInfo.files"
            :rules="formInfo.files.rules"
            class="mb-0"
            prop="attachments">
            <a-button
                type="link"
                size="small"
                class="p-0"
                @click="openFileModal">
                + {{ $t("Attach files") }}
            </a-button>
            <div v-show="value.attachments.length">
                <p>{{ $t("Attached files") }}</p>
                <FileAttach
                    ref="fileAttach"
                    :zIndex="1100"
                    :attachmentFiles="value.attachments"
                    :maxMBSize="50"
                    createFounder
                    listType="picture"
                    :showDeviceUpload="true"/>
            </div>
        </a-form-model-item>
    </div>

</template>

<script>
import FileAttach from "@apps/vue2Files/components/FileAttach";

export default {
    components: { 
        FileAttach
    },
    props: {
        value: { // form
            type: Object,
            required: true
        },
        formInfo: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        }
    },
    methods: {
        openFileModal() {
            this.$nextTick(() => {
                this.$refs.fileAttach.openFileModal();
            });
        },
    }
}
</script>
