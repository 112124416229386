<template>
    <a-drawer
        :width="drawerWidth"
        class="sprint_show_drawer"
        :visible="visible"
        :closable="false"
        :zIndex="1100"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div class="drawer_header">
            <template v-if="sprint">
                <div class="drawer_title">{{ sprint.name }}</div>
                <div class="flex items-center pl-3">
                    <a-button 
                        v-if="actions && actions.delete && actions.delete.availability"
                        type="ui" 
                        ghost
                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                        content="Удалить"
                        class="mr-2"
                        flaticon
                        shape="circle"
                        icon="fi-rr-trash"
                        @click="deleteSprint()" />
                    <a-button 
                        type="ui" 
                        ghost
                        class="mr-2"
                        flaticon
                        shape="circle"
                        icon="fi-rr-share" />
                    <a-button 
                        type="ui" 
                        ghost
                        flaticon
                        shape="circle"
                        icon="fi-rr-cross"
                        @click="visible = false" />
                </div>
            </template>
            <a-skeleton
                v-else
                active
                :paragraph="{ rows: 1 }" />
        </div>
        <div class="drawer_tabs">
            <a-tabs 
                v-if="sprint" 
                class="h-full"
                v-model="tab">
                <a-tab-pane key="info">
                    <template #tab>
                        О спринте
                    </template>
                </a-tab-pane>
                <a-tab-pane key="analytics">
                    <template #tab>
                        Аналитика
                    </template>
                </a-tab-pane>
            </a-tabs>
            <a-skeleton
                v-else
                active
                :paragraph="{ rows: 1 }" />
        </div>
        <div class="drawer_body">
            <template v-if="sprint">
                <div class="main_cont">
                    <a-tabs
                        :activeKey="tab" 
                        class="body_tab">
                        <a-tab-pane key="info">
                            <template #tab>О спринте</template>
                            <TaskTab 
                                ref="sprintTask"
                                :sprint="sprint" 
                                :page_name="page_name"
                                :actions="actions" />
                        </a-tab-pane>
                        <a-tab-pane key="analytics">
                            <template #tab>Аналитика</template>
                            <AnalyticsTab 
                                :sprint="sprint" 
                                :actions="actions" />
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div class="aside">
                    <div class="aside_block">
                        <div class="flex items-center justify-between mb-2">
                            <a-tag :color="statusColor">
                                {{ $t(`task.${sprint.status}`) }}
                            </a-tag>
                            <a-button 
                                v-if="actions.edit"
                                type="link" 
                                flaticon
                                v-tippy="{ inertia : true, duration : '[600,300]'}"
                                content="Редактировать"
                                icon="fi-rr-edit"
                                @click="editSprint()" />
                        </div>
                        <div v-if="sprint.begin_date && sprint.dead_line" class="aside_row">
                            <div class="aside_row__title">
                                Сроки
                            </div>
                            <div class="aside_row__value">
                                {{ $moment(sprint.begin_date).format('DD.MM.YY') }} - {{ $moment(sprint.dead_line).format('DD.MM.YY') }}
                            </div>
                        </div>
                        <div class="aside_row">
                            <div class="aside_row__title">
                                Задачи
                            </div>
                            <div class="aside_row__value">
                                {{ taskCount }}
                            </div>
                        </div>
                        <div v-if="sprint.target" class="aside_row">
                            <div class="aside_row__title">
                                Цель
                            </div>
                            <div class="aside_row__value">
                                {{ sprint.target }}
                            </div>
                        </div>
                        <div v-if="sprint.expected_result && sprint.expected_result.length" class="aside_row">
                            <div class="aside_row__title">
                                Ожидаемый результат
                            </div>
                            <div class="aside_row__value">
                                {{ sprint.expected_result.join(', ') }}
                            </div>
                        </div>
                        <div v-if="sprint.author" class="aside_row">
                            <div class="aside_row__title">
                                Автор
                            </div>
                            <div class="aside_row__value">
                                <Profiler
                                    :avatarSize="22"
                                    :user="sprint.author" />
                            </div>
                        </div>
                        <a-button 
                            v-if="sprint.status !== 'completed' && actions.set_status" 
                            type="primary" 
                            size="large"
                            block
                            ghost
                            :loading="loading"
                            @click="actionHandler()">
                            <template v-if="sprint.status === 'new'">
                                Запустить спринт
                            </template>
                            <template v-if="sprint.status === 'in_process'">
                                Завершить спринт
                            </template>
                        </a-button>
                    </div>
                </div>
            </template>
            <a-skeleton
                v-else
                active
                :paragraph="{ rows: 5 }" />
        </div>
    </a-drawer>
</template>

<script>
import { declOfNum } from '@/utils/utils.js'
import eventBus from '@/utils/eventBus'
import TaskTab from './TaskTab.vue'
import AnalyticsTab from './AnalyticsTab.vue'
export default {
    components: {
        TaskTab,
        AnalyticsTab
    },
    computed: {
        isInject() {
            return this.inject ? `_inject` : ''
        },
        taskCount() {
            return `${this.sprint.task_count} ${declOfNum(this.sprint.task_count, ['задача', 'задачи', 'задач'])}`
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 1575)
                return 1575
            else {
                return '100%'
            }
        },
        page_name() {
            return `tasks_sprint_${this.sprint.id}_task.TaskModel`
        },
        statusColor() {
            switch (this.sprint.status) {
            case "new":
                return '#1D65C0'
                break;
            case "in_process":
                return '#722ed1'
                break;
            case "completed":
                return '#52c41a'
                break;
            default:
                return '#1D65C0'
            }
        }
    },
    data() {
        return {
            visible: false,
            infoLoading: false,
            sprint: null,
            tab: 'info',
            loading: false,
            actions: null,
            inject: false
        }
    },
    watch: {
        '$route.query'(val) {
            if(val.sprint) {
                this.visible = true
            }
        }
    },
    methods: {
        deleteSprint() {
            this.$confirm({
                title: 'Вы действительно хотите удалить спринт?',
                closable: true,
                maskClosable: true,
                cancelText: 'Отмена',
                okText: 'Удалить',
                okType: 'danger',
                zIndex: 999999,
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', [{ id: this.sprint.id, is_active: false }])
                            .then(() => {
                                this.$message.success('Спринт удален')
                                this.visible = false
                                eventBus.$emit(`update_sprints_list${this.isInject}`)
                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                }
            })
        },
        editSprint() {
            this.visible = false
            eventBus.$emit('edit_sprint', {
                ...this.sprint,
                back: true,
                inject: this.inject
            })
        },
        async actionHandler() {
            if(this.sprint.status === 'new') {
                try {
                    this.loading = true
                    await this.$http.put(`tasks/sprint/${this.sprint.id}/update_status/`, {status: 'in_process'})
                    this.$message.success("Спринт начат")
                    eventBus.$emit('update_filter_tasks.TaskSprintModel')
                } catch(e) {
                    console.log(e)
                    this.$message.error(this.$t('error'))
                } finally {
                    this.loading = false
                    this.getSprint()
                }
            }
            if(this.sprint.status === 'in_process') {
                eventBus.$emit('end_sprint', this.sprint)
                /*try {
                    this.loading = true
                    await this.$http.put(`tasks/sprint/${this.sprint.id}/update_status/`, {status: 'completed'})
                    this.$message.success("Спринт завершен")
                    eventBus.$emit('update_filter_tasks.TaskSprintModel')
                } catch(e) {
                    this.$message.error(this.$t('error'))
                } finally {
                    this.loading = false
                    this.getSprint()
                }*/
            }
        },
        afterVisibleChange(vis) {
            if(vis) {
                if(this.$route.query?.viewProject || this.$route.query?.viewGroup)
                    this.inject = true
                this.getSprint()
            } else {
                this.closeDrawer()
            }
        },
        closeDrawer() {
            this.offEvents()
            const query = Object.assign({}, this.$route.query)
            this.sprint = null
            this.actions = null
            this.inject = false
            if(query.sprint) {
                if(query.sptab)
                    delete query.sptab
                delete query.sprint
                this.$router.push({query})
            }
        },
        async getSprintActions(query) {
            try {
                const { data } = await this.$http.get(`/tasks/sprint/${query.sprint}/action_info/`)
                if(data) {
                    this.actions = data
                }
            } catch(e) {
                console.log(e)
            }
        },
        sprintReload() {
            this.$nextTick(() => {
                if(this.$refs.sprintTask) {
                    this.$refs.sprintTask.tableReload()
                }
            })
            this.getSprint(true)
        },
        offEvents() {
            if(this.sprint)
                eventBus.$off(`update_sprint_${this.sprint.id}`)
            eventBus.$off('update_sprint_detail')
        },
        async getSprint(reload = false) {
            try {
                if(!reload)
                    this.infoLoading = true
                const query = Object.assign({}, this.$route.query)
                const { data } = await this.$http.get(`/tasks/sprint/${query.sprint}/`)
                if(data) {
                    if(!reload)
                        await this.getSprintActions(query)
                    this.sprint = data
                    if(!reload) {
                        eventBus.$on('update_sprint_detail', () => {
                            this.sprintReload()
                        })
                        eventBus.$on(`update_sprint_${this.sprint.id}`, () => {
                            this.sprintReload()
                        })
                    }
                }
            } catch(error) {
                if(error && error.detail) {
                    if(error.detail === 'Не найдено.' || error.detail === 'Страница не найдена.' || error.detail === 'У вас недостаточно прав для выполнения данного действия.') {
                        this.$message.warning(this.$t('task.task_not_found'))
                    } else {
                        this.$message.error(this.$t('task.error'))
                    }
                } else {
                    this.$message.error(this.$t('task.error'))
                }
                this.visible = false
                this.closeDrawer()
                this.offEvents()
            } finally {
                if(!reload)
                    this.infoLoading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.aside_row{
    &:not(:last-child){
        margin-bottom: 10px;
    }
    &__title{
        opacity: 0.6;
    }
}
.sprint_show_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-body{
            padding: 0px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .drawer_header{
            padding: 10px 30px;
            border-bottom: 1px solid var(--border2);
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .drawer_title{
                font-weight: 400;
                font-size: 16px;
                line-height: 18.75px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .ant-skeleton{
                .ant-skeleton-paragraph{
                    display: none;
                }
                .ant-skeleton-title{
                    margin: 0px;
                }
            }
        }
        .drawer_tabs{
            border-bottom: 1px solid var(--border2);
            height: 48px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            .ant-skeleton{
                .ant-skeleton-paragraph{
                    display: none;
                }
                .ant-skeleton-title{
                    margin: 0px;
                }
            }
            .ant-tabs-bar{
                background: transparent;
                border-bottom: 0px;
                margin: 0px;
                .ant-tabs-tab{
                    padding: 14px 16px;
                }
            }
        }
        .drawer_body{
            overflow-y: auto;
            flex-grow: 1;
            display: grid;
            gap: 30px;
            grid-template-columns: 1fr 300px;
            padding: 20px 30px;
            .ant-tabs{
                .ant-tabs-bar{
                    display: none;
                }
            }
        }
    }
    .aside_block{
        background: #F8F8F8;
        border-radius: 8px;
        padding: 15px;
        border: 1px solid var(--border2);
        color: #000;
    }
}
</style>