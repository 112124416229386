<template>
    <div ref="task_tab">
        <div class="flex items-center justify-between mb-4">
            <PageFilter 
                model="tasks.TaskModel"
                :key="page_name"
                size="large"
                :excludeFields="['sprint__exclude', 'sprint']"
                :getPopupContainer="getPopupContainer"
                :page_name="page_name" />
            <div class="flex items-center">
                <SettingsButton
                    :pageName="page_name"
                    :zIndex="1200" />
                <a-button 
                    v-if="actions && actions.set_task && actions.set_task.availability && sprint.status !== 'completed'"
                    type="primary"
                    size="large"
                    flaticon
                    class="ml-2"
                    icon="fi-rr-plus"
                    @click="addTask()">
                    Добавить задачи
                </a-button>
            </div>
        </div>
        <UniversalTable 
            ref="taskTable"
            model="tasks.TaskModel"
            :pageName="page_name"
            tableType="tasks"
            autoHeight
            :endpoint="`/tasks/sprint/${sprint.id}/tasks_list/?task_type=task,stage`"
            :params="queryParams"
            :openHandler="openTask"
            :main="false"
            taskType="task"
            :takeTask="takeTask"
            showChildren
            extendDrawer
            :hash="false" />
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import UniversalTable from '@/components/TableWidgets/UniversalTable'
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import eventBus from '@/utils/eventBus'
export default {
    components: {
        PageFilter,
        UniversalTable,
        SettingsButton
    },
    props: {
        sprint: {
            type: Object,
            required: true
        },
        actions: {
            type: Object,
            required: true
        },
        page_name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            queryParams: {}
        }
    },
    methods: {
        addTask() {
            eventBus.$emit('sprint_add_task', this.sprint.id)
        },
        getPopupContainer() {
            return this.$refs.task_tab
        },
        openTask(item) {
            const query = Object.assign({}, this.$route.query)
            if(query.task && Number(query.task) !== item.id || !query.task) {
                query.task = item.id
                this.$router.push({query})
            }
        },
        takeTask(task) {

        },
        tableReload() {
            this.$nextTick(() => {
                this.$refs.taskTable.reloadTableData()
            })
        }
    }
}
</script>